import { http } from '@/api/Connect';
import { BASE_OPERATOR } from '@/api/CopyStake/BaseUrls';
import { AxiosResponse } from 'axios';

import {
  DateRangeParams,
  ICopyStakeStatsStreamerParams,
  ICopyStakeStatsStreamersResponse,
  TStreamerStats,
  TStreamerStatsPayload
} from '@/api/schema';

const API_TRANSACTION_STATS = (): string =>
  `${BASE_OPERATOR()}/transaction-stats`;

export async function getStreamStats(
  params: TStreamerStatsPayload
): Promise<TStreamerStats> {
  return http
    .get(API_TRANSACTION_STATS(), {
      params,
      cacheTime: 30 * 1000
    })
    .then(({ data }: AxiosResponse<TStreamerStats>) => data);
}

export async function getCopyBetStatsStreamer(
  params: ICopyStakeStatsStreamerParams
): Promise<ICopyStakeStatsStreamersResponse> {
  return http
    .get(`${API_TRANSACTION_STATS()}/streamer`, {
      params,
      cacheTime: 0
    })
    .then(({ data }: AxiosResponse<ICopyStakeStatsStreamersResponse>) => data);
}

export async function getCopyBetStatsStreamerCsv(
  params: DateRangeParams
): Promise<string> {
  return http
    .get(`${API_TRANSACTION_STATS()}/streamer/csv`, {
      params
    })
    .then(({ data }: AxiosResponse<string>) => data);
}
