import { http } from '@/api/Connect';
import { AxiosResponse } from 'axios';

import {
  TStreamTransactionsByGameProviderPayload,
  TStreamTransactionsPayload,
  TStreamTransactionsResponse
} from '@/api/schema';

const API_TRANSACTIONS = (): string => '/api/v2/tx/copy-stake';

export async function getStreamTransactions(
  params: TStreamTransactionsPayload
): Promise<TStreamTransactionsResponse> {
  return http
    .get(`${API_TRANSACTIONS()}`, {
      params,
      cacheTime: 30 * 1000
    })
    .then(({ data }: AxiosResponse<TStreamTransactionsResponse>) => data);
}

export async function downloadStreamCsvReport(
  params: TStreamTransactionsPayload
): Promise<string> {
  return http
    .get(`${API_TRANSACTIONS()}/csv`, { params })
    .then(({ data }: AxiosResponse<string>) => data);
}

export async function getStreamTransactionsByGameProvider(
  params: TStreamTransactionsByGameProviderPayload
): Promise<TStreamTransactionsResponse> {
  const { data } = await http.get(`${API_TRANSACTIONS()}/game-provider`, {
    params,
    cacheTime: 30 * 1000
  });
  return data as TStreamTransactionsResponse;
}

export async function downloadStreamCsvReportByGameProvider(
  params: TStreamTransactionsByGameProviderPayload
): Promise<string> {
  const { data } = await http.get(`${API_TRANSACTIONS()}/game-provider/csv`, {
    params
  });
  return data as string;
}
